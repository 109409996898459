import React, { useState, useRef } from "react";
import Alert from "react-s-alert";

import { JsonData, JsonEditor } from "json-edit-react";
import { observer } from "mobx-react-lite";

import {
  getVideoUploadUrlJson,
  getFetchVideoBatchResultJson,
  postRequestVideoBatchJson,
  postCancelVideoBatchJson,
} from "../../external/api";
import useStore from "../../hooks/useStore";
import { BatchJob } from "../../stores/aiStore";
import UploadButton from "../Header/UploadButton";

const ToolbarBatch: React.FC = observer(() => {
  const { aiStore, authStore, uiStore } = useStore();
  const [processLog, setProcessLog] = useState<any>({
    message: "no job selected",
  });

  const uploadVideo = async (files: File[]) => {
    try {
      uiStore.setInprogress(true);
      const { uploadUrl, objKey } = await getVideoUploadUrlJson();
      const theFile = files[0];

      const response = await fetch(uploadUrl, {
        method: "PUT",
        body: theFile,
        headers: {
          "Content-Type": theFile.type,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(
          `Failed to upload video. Status: ${response.status}, Body: ${errorText}`
        );
        Alert.error("Failed to upload video");
      } else {
        aiStore.addOrUpdateObjKeyForVideoBatch(theFile.name, objKey);
        Alert.success("Video uploaded successfully");
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      Alert.error("An error occurred while uploading the video");
    } finally {
      uiStore.setInprogress(false);
    }
  };

  const changeTargetVideo = (event: React.ChangeEvent<HTMLSelectElement>) => {
    aiStore.setLatestObjKeyForVideoBatch(event.target.value);
  };

  const requestBatchJob = async () => {
    if (!aiStore.latestObjKeyForVideoBatch) {
      Alert.error("No video uploaded yet");
      return;
    }
    uiStore.setInprogress(true);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let script: any = aiStore.videoBatchScript;
    script = {
      ...script,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      start: script.start
        .split(":")
        .reduce((m: number, s: string) => m * 60 + parseFloat(s), 0),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      end: script.end
        .split(":")
        .reduce((m: number, s: string) => m * 60 + parseFloat(s), 0),
    };
    console.log(script);

    const response = await postRequestVideoBatchJson(
      aiStore.latestObjKeyForVideoBatch,
      script
      // JSON.parse(aiStore.videoBatchScript)
    );
    uiStore.setInprogress(false);
    console.log(response);
    aiStore.addJob(response);
    Alert.success("Job requested successfully");
  };

  const fetchMonitorImageAsDataURL = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const cancelJob = async (job: BatchJob) => {
    uiStore.setInprogress(true);
    const response = await postCancelVideoBatchJson(job.jobId);
    uiStore.setInprogress(false);
    aiStore.updateJob(response);
  };

  const fetchResult = async (job: BatchJob) => {
    uiStore.setInprogress(true);
    const response = await getFetchVideoBatchResultJson(job.jobId);
    uiStore.setInprogress(false);
    aiStore.updateJob(response);

    if (response.status === "processing") {
      const originalImageUrl = await fetchMonitorImageAsDataURL(
        job.originalImageUrl
      );
      const monitorImageUrl = await fetchMonitorImageAsDataURL(
        job.monitorImageUrl
      );

      if (monitorImageUrl) {
        aiStore.setTargetImageData(
          monitorImageUrl,
          "video-batch-monitor",
          originalImageUrl
        );
      }
    }
  };

  const displayLog = (job: BatchJob) => {
    console.log(job);
    if (job.log) {
      const log = { job_id: job.jobId, ...JSON.parse(job.log) };
      setProcessLog(log);
    }
  };

  const deriveTime = (jobId: string) => {
    return jobId.slice(-6).replace(/(\d{2})(\d{2})(\d{2})/, "$1:$2:$3");
  };

  return (
    <div className="toolbar__content">
      {authStore.canUseBatch() ? (
        <>
          <div className="preview__header">
            <UploadButton
              onFileSelected={uploadVideo}
              allowDirectory={false}
              assumeVideo={true}
            />
            &nbsp;&nbsp;&nbsp;
            {/* <span>{aiStore.latestObjKeyForVideoBatch}</span> */}
            <select
              className="uploaded-video"
              value={aiStore.latestObjKeyForVideoBatch}
              onChange={changeTargetVideo}
            >
              {Object.entries(aiStore.objKeysForVideoBatch).map(
                ([fileName, objKey]) => (
                  <option key={objKey} value={objKey}>
                    {fileName.length > 30
                      ? `${fileName.substring(0, 30)}...`
                      : fileName}
                  </option>
                )
              )}
            </select>
          </div>
          <div
            className="toolbar__option"
            onClick={() => {
              requestBatchJob();
            }}
          >
            <p>Request new job</p>
          </div>

          <table className="batch-job">
            <thead>
              <tr>
                <th>ID</th>
                <th>Status</th>
                <th>Progress</th>
                <th></th>
                <th></th>
                <th>Log</th>
              </tr>
            </thead>
            <tbody>
              {aiStore.videoBatchJobs.map((job, index) => (
                <tr key={job.jobId}>
                  <td>{deriveTime(job.jobId)}</td>
                  <td>{job.status}</td>
                  <td>
                    {job.result ? (
                      <a href={job.result} className="download-link">
                        DL
                      </a>
                    ) : (
                      ""
                    )}{" "}
                    {job.progress ? `${job.progress}%` : ""}
                  </td>
                  <td>
                    <button onClick={() => fetchResult(job)}>Check</button>
                  </td>
                  <td>
                    {job.status === "succeeded" ||
                    job.status === "failed" ||
                    job.status === "cancelled" ? (
                      <button onClick={() => aiStore.removeJob(job)}>
                        Del
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to cancel this job?"
                            )
                          ) {
                            cancelJob(job);
                          }
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </td>
                  <td>
                    <button onClick={() => displayLog(job)}>View</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="toolbar__block">
            <div className="toolbar__composite_wrapper">
              <JsonEditor
                data={aiStore.videoBatchScript}
                setData={(data: JsonData) => {
                  aiStore.setVideoBatchScript(data);
                }}
                rootName="script"
                enableClipboard={false}
                restrictDelete={true}
                showCollectionCount={false}
                restrictTypeSelection={true}
                theme="monoDark"
                rootFontSize={13}
              />
              <JsonEditor
                data={processLog}
                theme="monoDark"
                rootName="log"
                restrictEdit={true}
                restrictDrag={true}
                restrictDelete={true}
                restrictAdd={true}
                enableClipboard={false}
              />
            </div>
          </div>
        </>
      ) : (
        <div>Not available to free users</div>
      )}
    </div>
  );
});

export default ToolbarBatch;
